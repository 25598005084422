import React, { Component, useState, useEffect, useContext, useReducer, useMemo, useRef, lazy, Suspense } from 'react';
import { NavLink, Outlet, Routes, Route, useParams, useSearchParams, useLocation } from 'react-router-dom';
import SelectContext from '../extension/SelectContext';
import Sprite from '../extension/Sprite';

import { ToastContainer, toast } from 'react-toastify';
import { Audio } from 'react-loader-spinner';
import Loader from '../extension/Loader_v.0.5';

const People = ({ nameData, smallFont = false, id }) => {
    const datalist = "15.10.2024";
    const { storage } = useContext(SelectContext);
    const [loading, setLoading] = useState(true);  // Отслеживаем загрузку

  const [text, setText] = useState({
      loading: `Loading Database...`,
      total: "Total in database",
      return: "Return to list",
      datalist: `On date: ${datalist}`,
      info: "",
      video: 'Open video.',
      wiki: 'Open Wikipedia.',
      other: 'Open link.',  
  })

  useEffect(() => {
        console.log("Language changed:", storage.language);
        if (storage.language === "EN") {
            setText({
                loading: "Loading Database...",  
                total: "Total in database",
                return: "Return to list",
                datalist: `On date: ${datalist}`,
                info: "",   
                video: 'Open video.',
                wiki: 'Open Wikipedia.',
                other: 'Open link.',             
            })
        } else if (storage.language === "RU") {
            setText({
                loading: "Загрузка базы данных...",
                total: "Всего в базе данных",
                return: "Вернуться к списку",
                datalist: `На дату: ${datalist}`,
                info: "",
                video: 'Открыть видео.',
                wiki: 'Открыть Википедию.',
                other: 'Открыть ссылку.', 
            })
        } else {
            setText({
                loading: "Loading Database...",
                total: "Total in database",
                return: "Return to list",
                datalist: `On date: ${datalist}`,
                info: "",
                video: 'Open video.',
                wiki: 'Open Wikipedia.',
                other: 'Open link.',  
            })
        };
  }, [storage.language]);

    function getTitle(index) {
        // console.log(index)
        if (storage.language === "RU") {
            return storage.categoriesData[index].text.title.ru
        } else if (storage.language === "EN") {
            return storage.categoriesData[index].text.title.en
        } else {
            return storage.categoriesData[index].text.title.en
        }
  }
  
    function getInfo(index) {
        // console.log(index)
        if (storage.language === "RU") {
            return storage.categoriesData[index].text.info.ru
        } else if (storage.language === "EN") {
            return storage.categoriesData[index].text.info.en
        } else {
            return storage.categoriesData[index].text.info.en
        }
    }



  const [dataCelebrities, setDataCelebrities] = useState(null);
  useEffect(() => {
    setDataCelebrities(null); // Очищаем предыдущие данные
    setLoading(true); // Устанавливаем состояние загрузки
    fetch(`${process.env.PUBLIC_URL}/data/celebrities/${nameData}.json`)
      .then((response) => response.json())
      .then((jsonData) => {
        setDataCelebrities(jsonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Ошибка загрузки файла:', error);
        toast.error(storage.language === "RU" ? `Ошибка загрузки файла: ${error}` : `Error to load file: ${error}`);
        // setDataContracts([]);
        setLoading(false);
      }
    );
  }, [nameData]);

  useEffect(() => console.log(`Total: ${(dataCelebrities || []).filter(item => item.lang.toUpperCase() === storage.language).length}`), [dataCelebrities]);
    

    
// -------------------- LOADER ---------------------

    // Загрузчик
    
//   const loader = <div className='loader'>
//     <Audio className='loader' height="80" width="80" radius="9" color="inherit" ariaLabel="loading" />
//     <h2 style={{textAlign: "center"}}>{text.loading}</h2>
//   </div>;
    
  const loader = <Loader time={400}
    symbol={ <img style={{ margin: "10px" }} className='loader__img' width={"50px"} alt="." src={process.env.PUBLIC_URL + `/img/png/diamondBsmall.png`} />}
    total={4} size={60} reverse={false} />;
    

  return (
    <section className='result__info'>
        {
            loading ? loader : 
            <section className='result__info__show'>
                <h2 style={{textAlign: "center", margin: "6px auto", fontSize: "18px"}}>{getTitle(id)}</h2>
                {/* <p>{text.info}</p> */}
                <p>{getInfo(id)}</p>
                {/* <p>{`${text.total}: ${dataCelebrities.filter(item => item.lang.toUpperCase() === storage.language).length || "Loading..."} | ${text.datalist}`}</p> */}
                <section className='celebrities__search'>
                    {dataCelebrities.filter(item => item.lang.toUpperCase() === storage.language).map((item, index) => 
                        <a key={item.id} className="nav-link" href={`#${item.id}`}>{item.name}</a>
                    )}
                </section>
                {dataCelebrities.filter(item => item.lang.toUpperCase() === storage.language).map((item, index )=>
                <section id={item.id} className={`celebrities__card ${nameData}`} key={index} >
                    {/* {item.url_photo === "" ? null : <img className='celebrities__card__img' loading="lazy" src={process.env.PUBLIC_URL + `/img/jpg/celebrities/${nameData}/${item.url_photo}`} alt={item.name} width={'230px'} />} */}
                        {item.url_photo === "" ? null :
                            <div key={index} className="isZoomBlock">
                            <img className='celebrities__card__img' loading="lazy" src={process.env.PUBLIC_URL + `/img/jpg/celebrities/${nameData}/${item.url_photo_sign || item.url_photo}`} alt={item.name} title={item.name} width={'230px'} onClick={event => storage.setSelect(
                          {
                            src: event.currentTarget.src,
                            alt: event.currentTarget.alt
                          }
                        )}/>
                            <div className="zoom"><Sprite id="zoom-in" /></div>
                            </div>
                        }
                    <h2 className='celebrities__card__info'>{item.name}</h2>
                    <p className={`celebrities__card__description date ${smallFont ? "smallFont" : null}`}>{`${item.date} | ${item.country}`}</p>
                    <p className={`celebrities__card__description ${smallFont ? "smallFont" : null}`}>{item.description}</p>
                    <div className={`celebrities__card__link ${smallFont ? "smallFont" : null}`}>
                      {item.links.wiki === "" ? null : <a title={text.wiki} className='href' href={item.links.wiki} target='_blank'><Sprite id="icon-wikipedia" /></a>}
                      {/* {item.links.video === "" ? null : <a className='href' href={item.links.video} target='_blank'><Sprite id="icon-film" /></a>} */}
                      {item.links.video === "" ? null :
                        <div title={text.video} className='href'
                        onClick={event => {
                          storage.setSelectVideo(item.links.video);
                          console.log("Selected video:", item.links.video);
                        }} ><Sprite id="icon-film" /> </div>}
                      {item.links.other === "" ? null : <a title={text.other} className='href' href={item.links.other} target='_blank'><Sprite id="icon-link" /></a>}
                    </div>
                </section>)}
            </section>
          }
    {/* <a className="nav-return" href='#list'>{text.return}</a> */}
    <section className='pageNavigator'>
      <a className="nav-return" href='#list'><Sprite id='icon-arrow-up' /></a>
      <a className="nav-return" href='#contacts'><Sprite id='icon-arrow-down' /></a>
    </section>
    </section>
  );
};

export default People;
