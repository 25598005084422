import React, { Component, useState, useEffect, useContext, useReducer, useMemo, useRef, lazy, Suspense } from 'react';
import { NavLink, Outlet, Routes, Route, useParams, useSearchParams, useLocation } from 'react-router-dom';
import SelectContext from './extension/SelectContext';


import { ToastContainer, toast } from 'react-toastify';
import { Audio } from 'react-loader-spinner';
import Loader from './extension/Loader_v.0.5';

const Celebrities = () => {
    const { storage } = useContext(SelectContext);

  const [text, setText] = useState({
      loading: `Loading Database...`,    
  })

  useEffect(() => {
        console.log("Language changed:", storage.language);
        if (storage.language === "EN") {
            setText({
                loading: "Loading Database...", 
            })
        } else if (storage.language === "RU") {
            setText({
                loading: "Загрузка базы данных...",
            })
        } else {
            setText({
                loading: "Loading Database...",
            })
        };
  }, [storage.language]);

    function getTitle(index) {
        // console.log(index)
        if (storage.language === "RU") {
            return storage.categoriesData[index].text.title.ru
        } else if (storage.language === "EN") {
            return storage.categoriesData[index].text.title.en
        } else {
            return storage.categoriesData[index].text.title.en
        }
    }
    
// -------------------- LOADER ---------------------
    
  const loader = <Loader time={400}
    symbol={ <img style={{ margin: "10px" }} className='loader__img' width={"50px"} alt="." src={process.env.PUBLIC_URL + `/img/png/diamondBsmall.png`} />}
    total={4} size={60} reverse={false} />;
    
  return (
    <section className='block__celebrities'>
            <section className='result'>
                <nav id='list' className='result__nav'>
                  <ul>
                        {
                          storage.categoriesData.map((item, index) =>
                              <li key={item.name} className='result__nav__link'><NavLink className='mark' to={item.name}>{getTitle(index)}</NavLink></li>
                          )
                        }
                    </ul>
                </nav>
            </section>
            <Outlet />
    </section>
  );
};

export default Celebrities;
