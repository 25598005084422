let dataIP = {
    ip: "Searching...",
    asn: "",
    city: "Unknow city",
    continent_code: "Unknow continent",
    country: "",
    country_area: 0,
    country_calling_code: "",
    country_capital: "",
    country_code: "",
    country_code_iso3: "",
    country_name: "Unknow country",
    country_population: 0,
    country_tld: "",
    currency: "",
    currency_name: "",
    in_eu: false,
    languages: "",
    latitude: "",
    longitude: "",
    network: "",
    org: "",
    postal: "",
    region: "Unknow region",
    region_code: "",
    timezone: "",
    utc_offset: "",
    version: "",
};

export default async function getIP() {
    // send('https://ipapi.co/json/', dataIP);

    const response = await fetch('https://ipapi.co/json/');
    try {
        if (!response.ok) {
            throw new Error(response.statusText);
        } else {
            dataIP = await response.json();
            // console.log(dataIP);
        }
    } catch (error) {
        console.log(error);
    };

    if (dataIP.ip === "Searching...") {
        dataIP.ip = "Not detected."
        console.log("WARNING! IP Adress not detected. Problem with connection. Waiting...");
    }

    const result = `Place / Место: ${dataIP.city}, ${dataIP.region}, ${dataIP.country_name}, ${dataIP.continent_code}. IP Adress / IP Адресс: ${dataIP.ip} ${dataIP.version} ${dataIP.org} Map / Карта: https://en.ipshu.com/picture/${dataIP.ip}.png`

    console.log(result);
    return result;
}
